import React, { useState, createContext} from 'react';


export const MyContext = createContext();

export const State = ({children}) => {

    const initData = {
        activeStep: 0,
        no:"",
        surname: {
            value: "",
            error: false,
            required: true,
            disabled: true,
        },
        givenname: {
            value: "",
            error: false,
            required: true,
            disabled: true,
        },
        nation: {
            value: "",
            error: false,
            required: true,
            disabled: true,
        },
        year: {
            value: "",
            error: false,
            required: true,
            disabled: true,
        },
        month: {
            value: "",
            error: false,
            required: true,
            disabled: true,
        },
        date: {
            value: "",
            error: false,
            required: true,
            disabled: true,
        },
        status: {
            value: "",
            error: false,
            required: true,
            disabled: true,
        },
        land_date_d: {
            value: "",
            error: false,
            required: true,
            disabled: true,
        },
        land_date_m: {
            value: "",
            error: false,
            required: true,
            disabled: true,
        },
        land_date_y: {
            value: "",
            error: false,
            required: true,
            disabled: true,
        },
        doc_type: {
            value: "",
            error: false,
            required: true,
            disabled: true,
        },
        passport_no: {
            value: "",
            error: false,
            required: true,
            disabled: true,
        },
        landing_permit_no:{
            value: "",
            error: false,
            required: true,
            disabled: true,
        },
        sex: {
            value: "",
            error: false,
            required: false,
            disabled: true,
        },
    };

    const receiptInitData = {
        sell_d: {
            value: "",
            error: false,
            required: false,
            disabled: true,
        },
        sell_m: {
            value: "",
            error: false,
            required: false,
            disabled: true,
        },
        sell_y: {
            value: "",
            error: false,
            required: false,
            disabled: true,
        },
        generalTotal: {
            value: 0,
            error: false,
            required: false,
            disabled: true,
        },
        consumTotal: {
            value: 0,
            error: false,
            required: false,
            disabled: true,
        },
        goods_type: {
            value: "",
            error: false,
            required: false,
            disabled: true,
        },
        goods_name: {
            value: "",
            error: false,
            required: false,
            disabled: true,
        },
        number: {
            value: "",
            error: false,
            required: false,
            disabled: true,
        },
        price: {
            value: "",
            error: false,
            required: false,
            disabled: true,
        },
        reduced: {
            value: "",
            error: false,
            required: false,
            disabled: true,
        },
        details: {},
    };

    const initUser = {
        userid:'',
        passwd:'',
        showPassword:false,
    }

    const initImageData = {
        pic_1_name: "",
        pic_2_name: "",
        pic_3_name: "",
    };

    //입력폼 데이터
    const [data , setData] = useState(initData);
    const [receiptData, setReceiptData] = useState(receiptInitData);
    const [imageData, setImageData] = useState(initImageData);
    //로그인 데이터
    const [user, setUser] = useState(initUser);

    //전역으로 보낼 데이터
    const result = { imageData, setUser,user, setUser, data, setData, receiptData, setReceiptData };


    return (
        <div>
            <MyContext.Provider value={result}>
                {children}
            </MyContext.Provider>
        </div>
    )
}

