import React, { useContext,} from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { MyContext } from "../../data/State";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Main from "../../screen/Main";

const useStyles = makeStyles((theme) => ({
    title: {
        margin: "0px 0px 50px 0px",
    },
    subTitle: {
        margin: "50px 0px 10px 20px",
        color: "#1e3067",
        fontWeight: "700!important",
        fontFamily: "inherit",
        fontSize: "1rem"
    },
    tableCellTitle: {
        backgroundColor: "rgb(230,235,235)",
        width: "20rem",
    },
    tableCellBody: {
        width: "60rem",
    },
    titleTypo: {
        fontFamily: "sans-serif",
        fontWeight: "bold",
    },
}));

const transDoc_Type = (content) =>{
    switch (content) {
        case 1:
            content = "旅券（パスポート）";
            break;
        case 2:
            content = "船舶観光上陸許可書（旅券（パスポート）の写しの添付又は裏面印刷がある場合）";
            break;
        case 3:
            content = "船舶観光上陸許可書（旅券（パスポート）の写しの添付及び裏面印刷がない場合）";
            break;
        case 4:
            content = "乗員上陸許可書";
            break;
        case 5:
            content = "緊急上陸許可書";
            break;
        case 6:
            content = "遭難による上陸許可書";
            break;
        default:
            break;
    }

    return content;
}

const transSex = (content) => {
    switch (content) {
        case "MAN":
            content = "男性";
            break;
        case "WOMAN":
            content = "女性";
            break;
        case "OTHER":
            content = "その他";
            break;
        default:
            break;
    }

    return content;
};

const transStatus = (content) => {
    switch (content) {
        case 11:
            content = "短期滞在";
            break;
        case 14:
            content = "外交";
            break;
        case 17:
            content = "公用";
            break;
        case 21:
            content = "芸術";
            break;
        case 24:
            content = "宗教";
            break;
        case 27:
            content = "報道";
            break;
        case 31:
            content = "法律・会計業務";
            break;
        case 34:
            content = "医療";
            break;
        case 37:
            content = "興行";
            break;
        case 41:
            content = "文化活動";
            break;
        case 44:
            content = "留学";
            break;
        case 47:
            content = "研修";
            break;
        case 51:
            content = "家族滞在";
            break;
        case 54:
            content = "特定活動";
            break;
        case 81:
            content = "日本人の配偶者等";
            break;
        case 85:
            content = "永住者の配偶者等";
            break;
        case 91:
            content = "上陸許可書による入国";
            break;
        case 95:
            content = "非居住者に該当する日本国籍の者";
            break;
        case 99:
            content = "その他";
            break;
        default:
            break;
    }

    return content;
};

const transGoods_Type = (content) => {
    switch (content) {
        case 1:
            content = "一般物品";
            break;
        case 2:
            content = "消耗品";
            break;
        default:
            break;
    }

    return content;
};

const transReduced = (content) => {
    switch (content) {
        case 0:
            content = "10%";
            break;
        case 1:
            content = "8%";
            break;
        default:
            break;
    }

    return content;
};

const TitleTypo = ({content,code}) =>{
    const classes = useStyles();

    if(code === "doc_type"){
        content = transDoc_Type(content);
    }else if(code === "sex"){
        content = transSex(content);
    }else if(code === "status"){
        content = transStatus(content);
    }else if(code === "goods_type"){
        content = transGoods_Type(content);
    }else if(code === "reduced"){
        content = transReduced(content);
    }

    return (
        <Typography className = {classes.titleTypo}>
            {content}
        </Typography>
    )
}


function Step_6() {
    const classes = useStyles();

    //전역 변수 불러오기
    const myContext = useContext(MyContext);

    const data = myContext.data;

    const receiptData = myContext.receiptData;


    //JSX
    return (
        <>
        <Main/>
        <Grid container justify="center">
            <Grid item xs={12}>
                <Typography variant="h4" className={classes.title}>
                    ・Step 6 最終確認
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="免税No." />
                                </TableCell>
                                <TableCell className={classes.tableCellBody}>
                                    <TitleTypo content={data.no} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.subTitle}>・購入者旅券情報登録</Typography>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="旅券等の種類" />
                                </TableCell>
                                <TableCell className={classes.tableCellBody}>
                                    <TitleTypo content={data.doc_type.value} code="doc_type" />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="旅券番号" />
                                </TableCell>
                                <TableCell className={classes.tableCellBody}>
                                    <TitleTypo content={data.passport_no.value} />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="姓名" />
                                </TableCell>
                                <TableCell className={classes.tableCellBody}>
                                    <TitleTypo content={data.surname.value + " " + data.givenname.value} />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="国籍" />
                                </TableCell>
                                <TableCell className={classes.tableCellBody}>
                                    <TitleTypo content={data.nation.value} />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="生年月日" />
                                </TableCell>
                                <TableCell className={classes.tableCellBody}>
                                    <TitleTypo
                                        content={data.date.value + "-" + data.month.value + "-" + data.year.value}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="性別" />
                                </TableCell>
                                <TableCell className={classes.tableCellBody}>
                                    <TitleTypo content={data.sex.value} code="sex" />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.subTitle}>・購入者入国情報</Typography>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="在留資格" />
                                </TableCell>
                                <TableCell className={classes.tableCellBody}>
                                    <TitleTypo content={data.status.value} code="status" />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="上陸年月日" />
                                </TableCell>
                                <TableCell className={classes.tableCellBody}>
                                    <TitleTypo content={data.passport_no.value} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.subTitle}>・販売場情報</Typography>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="販売識別符号" />
                                </TableCell>
                                <TableCell className={classes.tableCellBody}>
                                    <TitleTypo content="0123456789" />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="販売場名称" />
                                </TableCell>
                                <TableCell className={classes.tableCellBody}>
                                    <TitleTypo content="東京販売所" />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="販売場所在地" />
                                </TableCell>
                                <TableCell className={classes.tableCellBody}>
                                    <TitleTypo content="東京都新宿区歌舞伎町1-4-1 歌舞伎ビル5階" />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="事業者氏名" />
                                </TableCell>
                                <TableCell className={classes.tableCellBody}>
                                    <TitleTypo content="山田 太郎" />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="事業者納税地" />
                                </TableCell>
                                <TableCell className={classes.tableCellBody}>
                                    <TitleTypo content="東京都新宿区" />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.subTitle}>・免税販売商品情報</Typography>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="譲渡日時" />
                                </TableCell>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="伝票番号" />
                                </TableCell>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="一般物品" />
                                </TableCell>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="消耗品" />
                                </TableCell>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="合計" />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.tableCellBody}>
                                    <TitleTypo content="2020/08/31 10:00" />
                                </TableCell>
                                <TableCell className={classes.tableCellBody}>
                                    <TitleTypo content="5678901234" />
                                </TableCell>
                                <TableCell className={classes.tableCellBody}>
                                    <TitleTypo content={"￥" + receiptData.generalTotal.value} />
                                </TableCell>
                                <TableCell className={classes.tableCellBody}>
                                    <TitleTypo content={"￥" + receiptData.consumTotal.value} />
                                </TableCell>
                                <TableCell className={classes.tableCellBody}>
                                    <TitleTypo
                                        content={
                                            "￥" +
                                            Number(receiptData.generalTotal.value + receiptData.consumTotal.value)
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12} style={{ margin: "30px 0px 0px 0px" }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="No" />
                                </TableCell>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="物品区分" />
                                </TableCell>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="商品情報" />
                                </TableCell>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="数量" />
                                </TableCell>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="税率" />
                                </TableCell>
                                <TableCell className={classes.tableCellTitle}>
                                    <TitleTypo content="販売価格" />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(receiptData.details).map((value, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell className={classes.tableCellBody}>
                                            <TitleTypo content={index + 1} />
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody}>
                                            <TitleTypo content={value[1].goods_type} code="goods_type" />
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody}>
                                            <TitleTypo content={value[1].goods_name} />
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody}>
                                            <TitleTypo content={value[1].number} />
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody}>
                                            <TitleTypo content={value[1].reduced} code="reduced" />
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody}>
                                            <TitleTypo content={value[1].price} />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
        </>
    );
}

export default Step_6;
