import React, { useContext, useState, useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import { useStyles } from "../css/Syori_css";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { MyContext } from '../data/State';
import { ordinaryPostAPI } from "../service/ordinaryAPI";
import { countStatusEndPoint, getListEndPoint } from "../config/Config";
import Main from '../screen/Main';

function Syori() {
    const classes = useStyles();

    let myContext = useContext(MyContext);

    const setData = myContext.setData;
    const data = myContext.data;

    const [listData,setListData] = useState([]);


    useEffect(() => {
        const jKey = sessionStorage.getItem("jKey");

        async function getImageName() {

            let count;

            const temp = await ordinaryPostAPI(countStatusEndPoint, {}, jKey).then((result) => {
                
                console.log(result);
                return result;
            });

            count = temp.data.count;

            const temp2 = await ordinaryPostAPI(
                getListEndPoint,
                {
                    startIndex: 0,
                    itemCount: count,
                },
                jKey
            ).then((result) => {
                console.log(result);
                return result;
            });

             setListData(temp2.data.data);
        }

        getImageName();
    }, []);

    console.log(listData);

    return (
        <>
            <Main/>
            <Grid container className={classes.container} justify="flex-start" spacing={4}>
                <Grid item xs={12}>
                    <h2>免税処理</h2>
                </Grid>
                <Grid container item justify="center">
                    <MainBadge rows={rows} />
                </Grid>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">免税No.</TableCell>
                                    <TableCell align="center">処理ステータス</TableCell>
                                    <TableCell align="center">販売日時</TableCell>
                                    <TableCell align="center">事業者名</TableCell>
                                    <TableCell align="center">処理</TableCell>
                                    <TableCell align="center">作業担当者</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listData
                                    ? listData.map((value, index) => {
                                          console.log(value);
                                          return (
                                              <TableRow hover key={index}>
                                                  <TableCell align="center">{value.requestid}</TableCell>
                                                  <TableCell align="center">
                                                      <StatusChip2 status={value.status} />
                                                  </TableCell>
                                              </TableRow>
                                          );
                                      })
                                    : null}
                                {rows.map((row, index) => (
                                    <TableRow hover key={index}>
                                        <TableCell align="center">{row.no}</TableCell>
                                        <TableCell align="center">{statusChip(row.status)}</TableCell>
                                        <TableCell align="center">{row.date}</TableCell>
                                        <TableCell align="center" className={classes.company}>
                                            {row.company}
                                        </TableCell>
                                        <TableCell align="center">
                                            <SyoriChip
                                                status={row.status}
                                                number={row.no}
                                                setData={setData}
                                                data={data}
                                            />
                                        </TableCell>
                                        <TableCell align="center">{row.manager}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    );
}

const StatusChip2 = ({status}) => {
    let name = "";
    const style = {
        backgroundColor: "",
        color: "",
        fontWeight: 700,
    };
    switch (status) {
        case "CONF":
            name = "新規";
            style.backgroundColor = "rgb(220,53,69)";
            style.color = "white";
            break;
        case "PROC":
            name = "処理中";
            style.backgroundColor = "rgb(255,193,7)";
            style.color = "black";
            break;
        case "PASP":
            name = "旅券作業";
            style.backgroundColor = "rgb(40,167,69)";
            style.color = "white";
            break;
        case "RECE":
            name = "領収証作業";
            style.backgroundColor = "rgb(40,167,69)";
            style.color = "white";
            break;
        case "CPRO":
            name = "完了";
            style.backgroundColor = "rgb(23,162,184)";
            style.color = "white";
            break;
         
        default:
            break;
    }

    return <Chip size="medium" style={style} label={name} />;
}


function createData(no, status, date, company, syori, manager) {
    return { no, status, date, company, syori, manager };
}

const rows = [
    createData(600010, 0, '2020/10/01 19:00:00', '株式会社ともびトモビズ', 0, '未定'),
    createData(600009, 0, '2020/10/01 18:00:00', '株式会社ともびモタックス', 0, '山田 一郎'),
    createData(600009, 0, '2020/10/01 17:00:00', '株式会社ともびトモビズ', 0, '田中 次郎'),
    createData(600009, 1, '2020/10/01 16:00:00', '株式会社ともびトモビズ', 0, '鈴木 三郎'),
    createData(600009, 1, '2020/10/01 15:00:00', '株式会社ともびリモタックス', 0, '佐藤 四郎'),
    createData(600008, 2, '2020/10/01 14:00:00', '株式会社ともびリモタックス', 0, '上田 五郎'),
    createData(600007, 2, '2020/10/01 13:00:00', '株式会社ともびトモビズ', 0, '石田 六郎'),
    createData(600006, 3, '2020/10/01 12:00:00', '株式会社ともびリモタックス', 0, '水野 七郎'),
];


function statusChip(status){

    let name ='';
    const style = {
        backgroundColor : '',
        color:'',
        fontWeight:700,
    }
    switch(status){
        case 0:
            name = "未対応";
            style.backgroundColor = 'rgb(220,53,69)';
            style.color = 'white';
            break;
        case 1:
            name = "エラー";
            style.backgroundColor = "rgb(255,193,7)";
            style.color = 'black';
            break;
        case 2:
            name = "処理中";
            style.backgroundColor = "rgb(23,162,184)";
            style.color = 'white';
            break;
        case 3:
            name = "完了";
            style.backgroundColor = "rgb(40,167,69)";
            style.color = 'white';
            break;
        default:
            break;
    }

    return <Chip
        size="medium"
        style={style}
        label={name}
    />;
}

const SyoriChip = ({status,number,setData,data}) => {   
    
    let name = '';
    const style = {
        backgroundColor: '',
        color: '',
        fontWeight: 700,
    }
    let link = '/input';

    if(status === 0 || status === 1){
        name = "入力";
        style.backgroundColor = "rgb(30,48,103)";
        style.color = 'white';
    }else if(status ===2){
        name = "確認";
        style.backgroundColor = "rgb(255,193,7)";
        style.color = 'black';
    }else{
        name = "完了";
        style.backgroundColor = "rgb(160,166,171)";
        style.color = 'white';
    }

    return (
        <Link to={link} style={{textDecoration: "none"}}>
            <Chip
                clickable
                size="medium"
                style={style}
                label={name} 
                onClick={()=>{
                    window.scrollTo(0, 0);
                    setData({...data,no:number});
                    sessionStorage.setItem('number', number);
                }}
            />
        </Link>
    );
}

const MainBadge = ({rows}) => {

    const statusNo = [
        {
            backgroundColor : 'rgb(220,53,69)',
            name: "対応中",
            count: 0,
        },
        {
            backgroundColor: 'rgb(255,193,7)',
            name: "エラー",
            count: 0,
        },
        {
            backgroundColor: 'rgb(23,162,184)',
            name: "処理中",
            count: 0,
        },
        {
            backgroundColor: 'rgb(40,167,69)',
            name: "完了",
            count: 0,
        },
    ]

    rows.map((value) => {
        switch(value.status){
            case 0:
                statusNo[0].count++;
                break;
            case 1:
                statusNo[1].count++;
                break;
            case 2:
                statusNo[2].count++;
                break;
            case 3:
                statusNo[3].count++;
                break;
            default:
                break;
        }

        return null;
    });


    return (
        <div>
            {statusNo.map((value,index)=>{

                const style = {
                    backgroundColor : value.backgroundColor,
                    margin:"0px 10px 0px 10px",
                    fontweight: "bold",
                    color: "white",
                    fontSize: 30,
                };
                
                return(
                    <Button size="large" key={index} variant="contained" style={style}>
                        {value.name} {value.count}
                    </Button>
                )
            })}
        </div>
    )
}

export default Syori;