import React from "react";
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';


function NotFound() {
    const authenticated = sessionStorage.getItem('jKey') != null;


    return (
        <>
            <h1>Not Found</h1>
            <p>Sorry...</p>
            {authenticated ? <Link to="/main"><Button>To Main</Button></Link> : <Link to="/"><Button>To Login</Button></Link>}            
        </>
    );
}

export default NotFound;

