import axios from "axios";

export async function ordinaryPostAPI(endpoint, json,authorization) {
    console.log("ordinaryPostAPI");

    console.log(endpoint);
    console.log(json);
    console.log(authorization);

    const config = {
        headers: {

            Authorization : authorization,
        }
    };

    try {
        const result = await axios.post(endpoint, json, config);

        return result;
    } catch (err) {
        console.log(err);
    }
}


export async function ordinaryGetAPI(endpoint, content_type, authorization) {
    console.log("ordinaryGetAPI");

    console.log(endpoint);

    const config = {
        headers: {
            content_type: content_type,
            Authorization: authorization,
        },
    };

    try {
        const result = await axios.get(endpoint, config);

        return result;
    } catch (err) {
        console.log(err);
    }
}