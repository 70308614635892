import React from 'react';
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Login from './screen/Login';
import Main from "./screen/Main";
import NotFound from "./screen/NotFound";
import Exercise from "./exercise/Exercise";
import {State} from "./data/State";
import Syori from "./mainScreen/Syori";
import Account from "./mainScreen/Account";
import Osirase from "./mainScreen/Osirase";
import Rireki from "./mainScreen/Rireki";
import Seisan from "./mainScreen/Seisan";
import Tenpo from "./mainScreen/Tenpo";
import Toiawase from "./mainScreen/Toiawase";
import Badge from "@material-ui/core/Badge";
import Input from "./subScreen/Input";

function App() {
  const authenticated = sessionStorage.getItem('jKey') != null;

  //const authenticated = true;

  console.log(authenticated);
  
  return (
      <>
          <State>
              <Router>
                  <Switch>
                      <Route exact path="/" component={Login}></Route>
                      <Route exact path="/main" component={Main}></Route>
                      <Route exact path="/syori" component={Syori}></Route>
                      <Route exact path="/input" component={Input}></Route>
                      <Route exact path="/account" component={Account}></Route>
                      <Route exact path="/osirase" component={Osirase}></Route>
                      <Route exact path="/rireki" component={Rireki}></Route>
                      <Route exact path="/seisan" component={Seisan}></Route>
                      <Route exact path="/tenpo" component={Tenpo}></Route>
                      <Route exact path="/toiawase" component={Toiawase}></Route>
                      <Route component={NotFound}></Route>
                  </Switch>
              </Router>
          </State>
      </>
  );

 /*return(
   <>
    <State>
      <Exercise/>
     </State>
   </>
 );
 */
}

export default App;
