import React,{useContext, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Grid from "@material-ui/core/Grid";
import Step2 from "./screen/Step_2";
import Step3 from "./screen/Step_3";
import Step4 from "./screen/Step_4";
import Step5 from "./screen/Step_5";
import Step6 from "./screen/Step_6";
import Step7 from "./screen/Step_7";
import Step8 from "./screen/Step_8";
import { Divider } from "@material-ui/core";
import { MyContext } from "../data/State";
import BottomButton from "./BottomButton";



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
        margin:"200px 0px 0px 0px"
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    body:{
        padding:30,
        
    }
}));

function getSteps() {
    return [
        "購入者旅券情報登録",
        "購入者旅券情報確認",
        "入国スタンプ情報登録",
        "入国スタンプ情報確認",
        "商品情報登録",
        "商品情報確認",
        "国税庁に転送",
    ];
}


export default function Input() {
    const classes = useStyles();

    let myContext = useContext(MyContext);

    const data = myContext.data;
    const setData = myContext.setData;

    const steps = getSteps();

    const getStepContent = (activeStep) => {
    
        switch (activeStep) {
            case 0:
                return <Step2 />;
            case 1:
                return <Step3 />;
            case 2:
                return <Step4 />;
            case 3:
                return <Step5 />;
            case 4:
                return <Step6 />;
            case 5:
                return <Step7 />;
            case 6:
                return <Step8 />;
            default:
                return "Unknown step";
        }
    }

    useEffect(()=>{
        if(sessionStorage.getItem('number')){
            setData({...data,no:sessionStorage.getItem('number')});
        }
    },[])
        
    return (
        <Grid container justify="center">
            <Grid item xs={12}>
                <Stepper activeStep={data.activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <Divider />
            </Grid>
            <Grid container item xs={12} className={classes.body}>
                <Grid item xs={12}>
                    {getStepContent(data.activeStep)}
                </Grid>
                <Grid container item xs={12} justify="center">
                    <BottomButton />
                </Grid>
            </Grid>
        </Grid>
    );
}