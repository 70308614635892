import React from 'react';
import Grid from "@material-ui/core/Grid";
import { useStyles } from "../css/Main_css";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { List, ListItem, ListSubheader, ListItemText, Typography} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Route, Switch } from "react-router-dom";
import Syori from '../mainScreen/Syori';
import Account from '../mainScreen/Account';
import Osirase from '../mainScreen/Osirase';
import Rireki from '../mainScreen/Rireki';
import Seisan from '../mainScreen/Seisan';
import Tenpo from '../mainScreen/Tenpo';
import Toiawase from '../mainScreen/Toiawase';
import Badge from '@material-ui/core/Badge';
import Input from '../subScreen/Input';

function sidebarMenu(text){
  return (
    <Typography>{text}</Typography>
  )
}

function logOut(){
  window.location="/";
  sessionStorage.removeItem("jKey");
  sessionStorage.removeItem("number");
}

function Main(){
    
    const classes = useStyles();

    return (
        <>
            <header className={classes.header}>
                <Grid container spacing={3} alignItems="center" justify="space-between">
                    <Grid item>
                        <h1 className={classes.logo}>Remotax</h1>
                    </Grid>
                    <Grid item>
                        <Button onClick={logOut} className={classes.logoutB}>
                            ログアウト
                        </Button>
                    </Grid>
                </Grid>
                <div className={classes.horizonLine} />
            </header>

            <main className={classes.paddingTop}>
                <Grid container spacing={0}>
                    <Grid item xs={2} className={classes.sidebar}>
                        <List>
                            <ListSubheader disableSticky style={{ color: "white" }}>
                                編集者メニュー
                            </ListSubheader>

                            <Link to="/syori" className={classes.sidebarText}>
                                <ListItem
                                    button
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                    }}
                                >
                                    <ListItemText inset primary={sidebarMenu("免税処理")} />
                                    <Badge
                                        badgeContent={4}
                                        color="secondary"
                                        style={{ margin: "0px 20px 0px 0px" }}
                                    ></Badge>
                                </ListItem>
                            </Link>

                            <Link to="/rireki" className={classes.sidebarText}>
                                <ListItem
                                    button
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                    }}
                                >
                                    <ListItemText inset primary={sidebarMenu("免税完了履歴")} />
                                </ListItem>
                            </Link>

                            <Link to="/tenpo" className={classes.sidebarText}>
                                <ListItem
                                    button
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                    }}
                                >
                                    <ListItemText inset primary={sidebarMenu("店舗登録管理")} />
                                </ListItem>
                            </Link>

                            <Link to="/toiawase" className={classes.sidebarText}>
                                <ListItem
                                    button
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                    }}
                                >
                                    <ListItemText inset primary={sidebarMenu("お問い合わせ")} />
                                </ListItem>
                            </Link>

                            <Link to="/osirase" className={classes.sidebarText}>
                                <ListItem
                                    button
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                    }}
                                >
                                    <ListItemText inset primary={sidebarMenu("お知らせ配信")} />
                                </ListItem>
                            </Link>

                            <Divider />

                            <ListSubheader disableSticky style={{ color: "white" }}>
                                管理者メニュー
                            </ListSubheader>

                            <Link to="/account" className={classes.sidebarText}>
                                <ListItem
                                    button
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                    }}
                                >
                                    <ListItemText inset primary={sidebarMenu("アカウント管理")}></ListItemText>
                                </ListItem>
                            </Link>

                            <Link to="/seisan" className={classes.sidebarText}>
                                <ListItem
                                    button
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                    }}
                                >
                                    <ListItemText inset primary={sidebarMenu("精算管理")}></ListItemText>
                                </ListItem>
                            </Link>
                        </List>
                    </Grid>
                    <Grid item xs={10} className={classes.mainScreen}>  
                    </Grid>
                </Grid>
            </main>

            <footer className={classes.footer}>
                <Divider />
                footer
            </footer>
        </>
    );

}

export default Main;
