import React, { useContext, useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import { MyContext } from "../../data/State";
import image from "../../source/taxfree-passport-sample.png";
import TextField from "@material-ui/core/TextField";
import HelpIcon from "@material-ui/icons/Help";
import IconButton from "@material-ui/core/IconButton";
import HelpDialog from "../../components/HelpDialog";
import { NationCode, DialogTitle } from "../../components/text/NationCode";
import { MonthCode, MonthCodeTitle } from "../../components/text/MonthCode";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import { ordinaryPostAPI } from "../../service/ordinaryAPI";
import { reqRequestEndPoint, getImageFileEndPoint } from "../../config/Config";
import Main from "../../screen/Main";

const useStyles = makeStyles((theme) => ({
    title: {
        margin: "0px 0px 50px 0px",
    },
    image: {
        width: "700px",
        height: "430px",
    },
    inputForm: {
        position: "relative",
        top: 20,
    },
    selectLabel: {
        fontWeight: "bold",
    },
    selectPosition: {
        margin: "-20px 0px 0px 0px",
    },
    textLabel: {
        margin: "10px 0px 10px 0px",
        fontSize: 17,
        fontWeight: "bold",
    },
    monthSelectStyle: {
        width: 140,
        margin: "-10px 0px 0px 0px",
    },
    textFieldStyle_L: {
        width: 260,
    },
    textFieldStyle_S: {
        width: 150,
    },
    sexSelect: {
        margin: "-20px 0px 0px 0px",
        width: 150,
    },
    helpIcon: {
        margin: "5px 0px 0px 0px",
        width: 30,
        height: 30,
    },
    clearIcon: {
        left: 10,
    },
    monthClearIcon: {
        left: 10,
        top: 5,
    },
    sexClearIcon: {
        left: 20,
        bottom: 5,
    },
}));

function Step_3() {
    const classes = useStyles();

    const initLocalData = {};

    const initLocalDataForm = (id, name, helpText, errorText, placeholder, error, required) => {
        initLocalData[name] = {};
        initLocalData[name].id = id;
        initLocalData[name].name = name;
        initLocalData[name].helpText = helpText;
        initLocalData[name].errorText = errorText;
        initLocalData[name].placeholder = placeholder;
        initLocalData[name].error = error;
    };

    const initLocalDataFunc = () => {
        initLocalDataForm(0, "doc_type", "旅券等の種類", "", "", false);
        initLocalDataForm(1, "nation", "国籍(Issuing country)", "", "JPN", false);
        initLocalDataForm(2, "passport_no", "旅券番号(Passport No.)", "", "XS1234567", false);
        initLocalDataForm(3, "surname", "姓名(Surname)", "", "GAIMU", false);
        initLocalDataForm(4, "givenname", "名前(Given Name)", "", "SAKURA", false);
        initLocalDataForm(5, "date", "日(Date)", "", "20", false);
        initLocalDataForm(6, "month", "月(Month)", "", "", false);
        initLocalDataForm(7, "year", "年(Year)", "", "1979", false);
        initLocalDataForm(8, "sex", "性別(Sex)", "", "", false);

        return initLocalData;
    };

    const [pic_1_name, setPic_1_name] = useState();

    useEffect(() => {
        const jKey = sessionStorage.getItem("jKey");

        async function getImageName() {
            const temp = await ordinaryPostAPI(reqRequestEndPoint, { rid: 2 }, jKey).then((result) => {
                console.log(result);
                return result;
            });

            setPic_1_name(temp.data.data[0].pic1);
        }

        getImageName();
    }, []);

    let imageUrl;

    if (pic_1_name) {
        imageUrl = getImageFileEndPoint + pic_1_name;
    }

    //입력폼 데이터
    const [localData, setLocalData] = useState(initLocalDataFunc);

    //다이얼로그 오픈
    const [open, setOpen] = useState(false);
    const [open_m, setOpen_m] = useState(false);

    //전역 변수 불러오기
    let myContext = useContext(MyContext);
    const data = myContext.data;
    const setData = myContext.setData;

    const handleOnChange = (code) => (e) => {
        console.log(code);

        const value = e.target.value;
        const upperCaseVal = value.toUpperCase();
        const numVal = Number(value);
        const localError = checkValidation(code, value);

        const tempObj = JSON.parse(JSON.stringify(data));

        //tempObj[code].error=false;

        switch (code) {
            case "doc_type":
                tempObj[code].value = numVal;

                if (numVal === 3 || numVal === 4 || numVal === 5 || numVal === 6) {
                    tempObj["passport_no"].required = false;
                } else {
                    tempObj["passport_no"].required = true;
                }

                if (value) {
                    tempObj[code].value = numVal;
                    tempObj[code].error = false;
                } else {
                    tempObj[code].value = value;
                    tempObj[code].error = true;
                }
                break;
            case "nation":
                if (!localError) {
                    tempObj[code].value = upperCaseVal;
                    tempObj[code].error = false;
                }
                break;

            case "passport_no":
                if (!localError) {
                    tempObj[code].value = upperCaseVal;
                    tempObj[code].error = false;
                }
                break;
            case "surname":
                if (!localError) {
                    tempObj[code].value = upperCaseVal;
                    tempObj[code].error = false;
                }
                break;
            case "givenname":
                if (!localError) {
                    tempObj[code].value = upperCaseVal;
                    tempObj[code].error = false;
                }
                break;
            case "date":
                if (!localError) {
                    tempObj[code].value = value;
                    tempObj[code].error = false;
                }
                break;
            case "month":
                if (value) {
                    tempObj[code].value = value;
                    tempObj[code].error = false;
                } else {
                    tempObj[code].value = value;
                    tempObj[code].error = true;
                }
                break;
            case "year":
                if (!localError) {
                    tempObj[code].value = value;
                    tempObj[code].error = false;
                }
                break;
            case "sex":
                
                tempObj[code].value = value;
                tempObj[code].error = false;
                
                break;
            default:
                break;
        }

        console.log(tempObj);

        setData(tempObj);
    };

    //유효성 검사
    const checkValidation = (code, value) => {
        let errorText = "";
        let error = false;

        const regExp_space = /\s/g;
        const regExp_onlyEnglish = /^[a-zA-Z]*$/g;
        const regExp_onlyNumber = /^[0-9]*$/g;
        const regExp_numberEnglish = /^[0-9a-zA-Z]*$/g;
        const regExp_length_4 = /^.{0,4}$/g;
        const regExp_length_3 = /^.{0,3}$/g;
        const regExp_length_2 = /^.{0,2}$/g;
        const regExp_length_11 = /^.{0,11}$/g;

        switch (code) {
            case "doc_type":
                if (value === "") {
                    errorText = "無し不要";
                    error = true;
                }
                break;
            case "nation":
                if (regExp_space.test(value)) {
                    errorText = "スペース不要";
                    error = true;
                    break;
                }

                if (!regExp_onlyEnglish.test(value)) {
                    errorText = "英文字以外不要";
                    error = true;
                    break;
                }

                if (!regExp_length_3.test(value)) {
                    errorText = "3文字まで";
                    error = true;
                    break;
                }

                break;

            case "passport_no":
                if (regExp_space.test(value)) {
                    errorText = "スペース不要";
                    error = true;
                    break;
                }

                if (!regExp_numberEnglish.test(value)) {
                    errorText = "数字と英文字以外不要";
                    error = true;
                    break;
                }

                if (!regExp_length_11.test(value)) {
                    errorText = "11文字まで";
                    error = true;
                    break;
                }
                break;

            case "surname":
                if (regExp_space.test(value)) {
                    errorText = "スペース不要";
                    error = true;
                    break;
                }

                if (!regExp_onlyEnglish.test(value)) {
                    errorText = "英文字以外不要";
                    error = true;
                    break;
                }

                if (data.surname.value.length + data.givenname.value.length > 38) {
                    errorText = "姓名と名前は39文字まで";
                    error = true;
                    break;
                }
                break;

            case "givenname":
                if (regExp_space.test(value)) {
                    errorText = "スペース不要";
                    error = true;
                    break;
                }

                if (!regExp_onlyEnglish.test(value)) {
                    errorText = "英文字以外不要";
                    error = true;
                    break;
                }

                if (data.surname.value.length + data.givenname.value.length > 38) {
                    errorText = "姓名と名前は39文字まで";
                    error = true;
                    break;
                }

                break;

            case "date":
                if (regExp_space.test(value)) {
                    errorText = "スペース不要";
                    error = true;
                    break;
                }

                if (!regExp_onlyNumber.test(value)) {
                    errorText = "数字以外不要";
                    error = true;
                    break;
                }

                if (!regExp_length_2.test(value)) {
                    errorText = "2文字まで";
                    error = true;
                    break;
                }

                break;
            case "month":
                if (value === "") {
                    errorText = "無し不要";
                    error = true;
                }
                break;

            case "year":
                if (regExp_space.test(value)) {
                    errorText = "スペース不要";
                    error = true;
                    break;
                }

                if (!regExp_onlyNumber.test(value)) {
                    errorText = "数字以外不要";
                    error = true;
                    break;
                }

                if (!regExp_length_4.test(value)) {
                    errorText = "4文字まで";
                    error = true;
                    break;
                }

                break;
            case "sex":
                if (value === "") {
                    errorText = "無し不要";
                    error = true;
                }
                break;

            default:
                errorText = "prop's value error";
                error = true;
                break;
        }

        setLocalData({ ...localData, [code]: { ...localData[code], errorText: errorText, error: error } });
        return error;
    };

    //유효성검사 후 입력폼 데이터 갱신
    const handleValidation = (code, errorText, error) => {
        setLocalData({
            ...localData,
            [code]: { ...localData[code], errorText: errorText, error: error },
        });
    };

    //텍스트 필드 렌더링 함수
    const textField = (code, style) => {
        let labelMsg = "";

        if (localData[code].error) {
            labelMsg = localData[code].errorText;
        } else if (data[code].error) {
            labelMsg = localData[code].helpText;
        } else {
            labelMsg = localData[code].helpText;
        }

        return (
            <>
                <TextField
                    variant="outlined"
                    size="small"
                    disabled={data[code].disabled}
                    required={data[code].required}
                    label={labelMsg}
                    error={data[code].error || localData[code].error}
                    className={classes[style]}
                    value={data[code].value !== "" ? data[code].value : ""}
                    onChange={handleOnChange(code)}
                    placeholder={localData[code].placeholder}
                    InputProps={{
                        endAdornment: (
                            <>
                                {!data[code].disabled ? (
                                    <>
                                        <IconButton
                                            className={classes.clearIcon}
                                            size="small"
                                            onClick={() => {
                                                setData({ ...data, [code]: { ...data[code], value: "" } });
                                                handleValidation(code, localData[code].helpText, false);
                                            }}
                                        >
                                            {}
                                            <ClearIcon />
                                        </IconButton>
                                        <IconButton
                                            className={classes.clearIcon}
                                            size="small"
                                            onClick={() => {
                                                setData({ ...data, [code]: { ...data[code], disabled: true } });
                                            }}
                                        >
                                            {}
                                            <SaveIcon />
                                        </IconButton>
                                    </>
                                ) : (
                                    <IconButton
                                        className={classes.clearIcon}
                                        size="small"
                                        onClick={() => {
                                            setData({ ...data, [code]: { ...data[code], disabled: false } });
                                        }}
                                    >
                                        {}
                                        <EditIcon />
                                    </IconButton>
                                )}
                            </>
                        ),
                    }}
                />
            </>
        );
    };

    //여권 경우에 따라 여권번호 임의 표시 유무
    const passportNoTypo = (prop) => {
        return data[prop].required ? (
            <Typography className={classes.textLabel}>③　旅券番号</Typography>
        ) : (
            <Typography className={classes.textLabel}>③　旅券番号(任意)</Typography>
        );
    };

    console.log(data);
    console.log(localData);

    //JSX
    return (
        <>
        <Main/>
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h4" className={classes.title}>
                    ・Step 2 購入者旅券情報確認
                </Typography>
            </Grid>
            <Grid item xs={6} className={classes.image}>
                <img src={imageUrl} width="640px" height="440px" alt="passport_image"></img>
            </Grid>
            <Grid container item xs={6} className={classes.inputForm}>
                <Grid item xs={3}>
                    <Typography className={classes.selectLabel}>①　旅券等の種類</Typography>
                </Grid>
                <Grid container item xs={9}>
                    <Grid item xs={11}>
                        <FormControl className={classes.selectPosition} error={data.doc_type.error}>
                            <InputLabel required={data.doc_type.required}>旅券等の種類</InputLabel>
                            <Select
                                disabled={data.doc_type.disabled}
                                value={data.doc_type.value !== "" ? data.doc_type.value : ""}
                                native
                                onChange={handleOnChange("doc_type")}
                            >
                                <option value={""}></option>
                                <option value={1}>旅券（パスポート）</option>
                                <option value={2}>船舶観光上陸許可書（旅券の写しの添付又は裏面印刷がある場合）</option>
                                <option value={3}>船舶観光上陸許可書（旅券の写しの添付及び裏面印刷がない場合）</option>
                                <option value={4}>乗員上陸許可書</option>
                                <option value={5}>緊急上陸許可書</option>
                                <option value={6}>遭難による上陸許可書</option>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        {data.doc_type.disabled ? (
                            <IconButton
                                className={classes.clearIcon}
                                size="small"
                                onClick={() => {
                                    setData({ ...data, doc_type: { ...data["doc_type"], disabled: false } });
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        ) : (
                            <IconButton
                                className={classes.clearIcon}
                                size="small"
                                onClick={() => {
                                    setData({ ...data, doc_type: { ...data["doc_type"], disabled: true } });
                                }}
                            >
                                <SaveIcon />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
                <Grid container item xs={3}>
                    <Grid container item xs={8}>
                        <Typography className={classes.textLabel}>②　国籍</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <IconButton
                            onClick={() => {
                                setOpen(true);
                            }}
                            className={classes.helpIcon}
                        >
                            <HelpIcon></HelpIcon>
                        </IconButton>
                        {open ? (
                            <HelpDialog
                                onClose={() => {
                                    setOpen(false);
                                }}
                                open={open}
                                text={NationCode}
                                title={DialogTitle}
                            />
                        ) : null}
                    </Grid>
                </Grid>
                <Grid container item xs={9}>
                    {textField("nation", "textFieldStyle_L")}
                </Grid>
                <Grid item xs={3}>
                    {passportNoTypo("passport_no")}
                </Grid>
                <Grid container item xs={9}>
                    {textField("passport_no", "textFieldStyle_L")}
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.textLabel}>④　氏名</Typography>
                </Grid>
                <Grid container item xs={5}>
                    {textField("surname", "textFieldStyle_L")}
                </Grid>
                <Grid container item xs={4}>
                    {textField("givenname", "textFieldStyle_L")}
                </Grid>
                <Grid container item xs={3}>
                    <Grid item xs={8}>
                        <Typography className={classes.textLabel}>⑤　生年月日</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <IconButton
                            onClick={() => {
                                setOpen_m(true);
                            }}
                            className={classes.helpIcon}
                        >
                            <HelpIcon></HelpIcon>
                        </IconButton>
                        {open_m ? (
                            <HelpDialog
                                onClose={() => {
                                    setOpen_m(false);
                                }}
                                open={open_m}
                                text={MonthCode}
                                title={MonthCodeTitle}
                            />
                        ) : null}
                    </Grid>
                </Grid>
                <Grid container item xs={9}>
                    <Grid container item xs={4}>
                        {textField("date", "textFieldStyle_S")}
                    </Grid>
                    <Grid container item xs={4}>
                        <Grid item xs={9}>
                            <FormControl className={classes.monthSelectStyle} error={data.month.error}>
                                <InputLabel required={data.month.required}>月(Month)</InputLabel>
                                <Select
                                    disabled={data.month.disabled}
                                    value={data.month.value !== "" ? data.month.value : ""}
                                    native
                                    onChange={handleOnChange("month")}
                                >
                                    <option value={""}></option>
                                    <option value={"01"}>JAN/01</option>
                                    <option value={"02"}>FEB/02</option>
                                    <option value={"03"}>MAR/03</option>
                                    <option value={"04"}>APR/04</option>
                                    <option value={"05"}>MAY/05</option>
                                    <option value={"06"}>JUN/06</option>
                                    <option value={"07"}>JUL/07</option>
                                    <option value={"08"}>AUG/08</option>
                                    <option value={"09"}>SEP/09</option>
                                    <option value={"10"}>OCT/10</option>
                                    <option value={"11"}>NOV/11</option>
                                    <option value={"12"}>DEC/12</option>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            {data.month.disabled ? (
                                <IconButton
                                    className={classes.monthClearIcon}
                                    size="small"
                                    onClick={() => {
                                        setData({ ...data, month: { ...data["month"], disabled: false } });
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            ) : (
                                <IconButton
                                    className={classes.monthClearIcon}
                                    size="small"
                                    onClick={() => {
                                        setData({ ...data, month: { ...data["month"], disabled: true } });
                                    }}
                                >
                                    <SaveIcon />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container item xs={4}>
                        {textField("year", "textFieldStyle_S")}
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.selectLabel}>⑥　性別</Typography>
                </Grid>
                <Grid container item xs={9}>
                    <Grid item xs={3}>
                        <FormControl className={classes.sexSelect} error={data.sex.error}>
                            <InputLabel required={data.sex.required}>性別(Sex)</InputLabel>
                            <Select
                                disabled={data.sex.disabled}
                                value={data.sex.value !== "" ? data.sex.value : ""}
                                native
                                onChange={handleOnChange("sex")}
                            >
                                <option value={""}></option>
                                <option value={"MAN"}>男性</option>
                                <option value={"WOMAN"}>女性</option>
                                <option value={"OTHER"}>その他</option>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        {data.sex.disabled ? (
                            <IconButton
                                className={classes.sexClearIcon}
                                size="small"
                                onClick={() => {
                                    setData({ ...data, sex: { ...data["sex"], disabled: false } });
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        ) : (
                            <IconButton
                                className={classes.sexClearIcon}
                                size="small"
                                onClick={() => {
                                    setData({ ...data, sex: { ...data["sex"], disabled: true } });
                                }}
                            >
                                <SaveIcon />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </>
    );
}

export default Step_3;
