import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    header: {
        top: 0,
        left: 0,
        position: "fixed",
        width: "100%",
        height: 64,
        background: "white",
        borderBottom: "solid 1px",
        boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.5)",
        margin: 0,
        padding: 0,
        zIndex: 100,
    },
    logo: {
        margin: "10px 0px 0px 20px",
    },
    logoutB: {
        margin: "15px 20px 0px 0px",
    },
    paddingTop: {
        padding: "56px 0px 0px 0px",
        margin: "0px -8px 0px -8px",
    },
    sidebar: {
        background: "rgba(30, 48, 103, 1)",
        minHeight:"45rem"
    },
    footer: {
    },
    sidebarText: {
        color: "white",
        textDecoration: "none",
    },
    mainScreen: {
        minWidth: "50%",
    },
});