import axios from 'axios';

export async function LoginAPI(endpoint, json, config, event) {

    event.preventDefault();

    console.log("postAPI");

    function updateJwtKey(jKey) {
        sessionStorage.setItem("jKey", jKey);
        sessionStorage.setItem("mTime", Date.now());
    }

    
    try {
        const result = await axios.post(endpoint,json,config)

        console.log(result);

        if (result.data.jkey !== undefined) {
            updateJwtKey(result.data.jkey);
            window.location = "/main";
            console.log('login success');

        } else {
            console.log('login failed');
            alert('There is no user');
        }
    } catch (err) {
        console.log(err);
    }

}
