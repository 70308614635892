export const MonthCodeTitle = "月リスト";

export const MonthCode = [
    `01月　JAN`,
    `02月　FEB`,
    `03月　MAR`,
    `04月　APR`,
    `05月　MAY`,
    `06月　JUN`,
    `07月　JUL`,
    `08月　AUG`,
    `09月　SEP`,
    `10月　OCT`,
    `11月　NOV`,
    `12月　DEC`
];