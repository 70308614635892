import React, { useContext, useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { MyContext } from "../../data/State";
import { ordinaryPostAPI } from "../../service/ordinaryAPI";
import { kokuzeiEndPoint, kokuzeiConfig } from "../../config/Config";
import Button from "@material-ui/core/Button";
import Main from "../../screen/Main";

const useStyles = makeStyles((theme) => ({
    title: {
        margin: "0px 0px 50px 0px",
    },
    submitButton:{
        position:"relative",
        right: 25,
    }
}));


function Step_8() {
    const classes = useStyles();

    //전역 변수 불러오기
    const myContext = useContext(MyContext);
    const receiptData = myContext.receiptData;

    const data = myContext.data;

    const name = data.surname.value + " " + data.givenname.value;
    const nation = data.nation.value;
    const birth = data.year.value + "-" + data.month.value + "-" + data.date.value;
    const status = data.status.value;
    const land_date = data.land_date_y.value + "-" + data.land_date_m.value + "-" + data.land_date_d.value;
    const doc_type = data.doc_type.value;
    const passport_no = data.passport_no.value;

    const landing_permit_no = data.landing_permit_no.value;

    const sex = data.sex.value;
    const sell_date = receiptData.sell_y.value + "-" + receiptData.sell_m.value + "-" + receiptData.sell_d.value;

    const generalTotal = receiptData.generalTotal.value;
    const consumTotal = receiptData.consumTotal.value;




    const details = Object.entries(receiptData.details).map((value,index)=>{

        let result ={};
        
        Object.keys(value[1]).filter((keyValue)=>{
            return keyValue !== "canChange"
        }).map((keyMapValue)=>{
            console.log(keyMapValue);

            result = {...result,[keyMapValue]:value[1][keyMapValue]};
            return null;
        })

        result = {...result , serial : index + 1 };
        return result;
    })


    const apiObj = {
        name: name,
        nation: nation,
        birth: birth,
        status: status,
        land_date: land_date,
        doc_type: doc_type,
        passport_no: passport_no,
        landing_permit_no: "20Abc1234567",
        note: null,
        sex: sex,
        sell_date: sell_date,
        sell_time: "1530",
        receipt_no: "A2343456",
        general_total: generalTotal,
        consum_total: consumTotal,
        details: details,
    };

    
    const [apiResult, setApiResult] = useState();
    const [msg,setMsg] = useState("処理中です。");


    
    useEffect(() => {
        async function asyncKokuzeiApi(){
            const temp  = await ordinaryPostAPI(kokuzeiEndPoint, apiObj, kokuzeiConfig).then((result) => {
                console.log(result);
                console.log(result.data.success);
                setApiResult(result);
                setMsg(result.data.success);

                return result;
            });

            if(temp.data.success){
                console.log("success");
                setMsg("国税庁へデータ送信完了しました。");
            }else{
                console.log("failed");
                setMsg("国税庁へデータ送信中エラーが発生しました。");
            }
        }
        asyncKokuzeiApi();
    }, []);

    
    //JSX
    return (
        <>
        <Main/>
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h4" className={classes.title}>
                    ・Step 7 国税庁に転送
                </Typography>
            </Grid>

            <Grid item xs={12} align="center">
                <Typography variant="h4" className={classes.title}>
                    {msg}
                </Typography>
            
            </Grid>
        </Grid>
        </>
    );
}

export default Step_8;
