import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { FormControl } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: 300,
  },
  iconButton: {
    padding: 10,
  },
  textField: {
    width: "100%",
  },
  searchIcon: {
    margin: "0px 10px 0px 0px",
  },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function HelpDialog({ open, onClose, text, title}) {
  const classes = useStyles();

  const [result, setResult] = useState(text);

  useEffect(()=>{
    return () => {
      setResult(text);
    };
  },[]);


  const searchCode = (e) => {

    if (e.target.value === "" || e.target.value === null) {
      setResult(text);
    } else {

      let temp = [];

      text.map((value) => {
        const tempResult = value.toLowerCase();
        const tempTarget = e.target.value.toLowerCase();

        if (tempResult.includes(tempTarget)) {
          temp.push(value);
        }
        return null;

      });

      setResult(temp);
    }
  }

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={onClose}
      scroll="paper"
    >
      <DialogTitle>{title}</DialogTitle>
      <FormControl>
        <Grid container justify="center">
          <Grid item>
            <TextField
            onChange={searchCode}
              className={classes.textField}
            ></TextField>
          </Grid>
          <Grid item>
            <IconButton
              onClick={(e) => console.log(e.target.value)}
              className={classes.searchIcon}
            >
              <SearchIcon />
            </IconButton>
          </Grid>
        </Grid>
      </FormControl>
      <DialogContent>
        {result.map((value, index) => {
          return <div key={index}>{value}</div>;
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default HelpDialog;