import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { MyContext } from "../data/State";
import { Link } from "react-router-dom";
import {NationCode} from '../components/text/NationCode';
import { Alert, AlertTitle } from "@material-ui/lab";
import Input from '@material-ui/core/Input';

const useStyles = makeStyles((theme) => ({
    buttonPosition:{
        margin:"100px 0px 0px"
    },
    sidebarText: {
        textDecoration: "none",
    },
    saveButton: {
        borderRadius: "5px",
        backgroundColor: "rgb(128,128,128)",
        width: 65,
        height: 37,
        color: "white",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgb(128,128,128,0.7)",
        },
        "&:active": {
            backgroundColor: "rgb(128,128,128,0.5)",
        },
    },
    backButton: {
        borderRadius: "5px",
        backgroundColor: "rgb(245,0,87)",
        width: 65,
        height: 37,
        color: "white",
        "&:hover": {
            backgroundColor: "rgb(245,0,87,0.7)",
        },
        "&:active": {
            backgroundColor: "rgb(245,0,87,0.5)",
        },
    },
    nextButton: {
        borderRadius: "5px",
        backgroundColor: "rgb(63,81,181)",
        width: 65,
        height: 37,
        color: "white",
        "&:hover": {
            backgroundColor: "rgb(63,81,181,0.7)",
        },
        "&:active": {
            backgroundColor: "rgb(63,81,181,0.5)",
        },
    },
}));


export default function BottomButton() {
    const classes = useStyles();

    let myContext = useContext(MyContext);

    const data = myContext.data;
    const setData = myContext.setData;

    const receiptData = myContext.receiptData;
    const setReceiptData = myContext.setReceiptData;

    const errorMsgArr = [];
    let errorMsg = "";


    const handleNext = () => {

        const errorData = JSON.parse(JSON.stringify(data));
        
        if (data.activeStep === 0 || data.activeStep === 1) {

            const createErrorMsg = (code, errMsg) => {
                errorMsgArr.push(errMsg);
                errorData[code].error=true;
            };

            const errorTrue = (code) => {
                console.log(2);
                errorData[code].error = false;
                errorData[code].disabled = true;
            }
           
            if (data.doc_type.value === "" || data.doc_type.value === null) {
                createErrorMsg("doc_type", "旅券等の種類が空欄です。設定してください。");
            } else {
                console.log(1);
                errorTrue("doc_type");
            }

            const cutNationCode = [];
            NationCode.forEach((code) => cutNationCode.push(code.substring(0, 3)));

            if (data.nation.value === "" || data.nation.value === null) {
                createErrorMsg("nation", "国籍(Issuing country)が空欄です。入力してください。");
            } else if (cutNationCode.indexOf(data.nation.value) === -1) {
                createErrorMsg("nation", "存在しない国籍(Issuing country)です。");
            } else {
                errorTrue("nation");
            }

            if (data.passport_no.required && (data.passport_no.value === "" || data.passport_no.value === null)) {
                createErrorMsg("passport_no", "旅券番号(Passport No.)が空欄です。入力してください。");
            } else {
                errorTrue("passport_no");
            }

            if (data.surname.value === "" || data.surname.value === null) {
                createErrorMsg("surname", "姓名(Surname)が空欄です。入力してください。");
            } else {
                errorTrue("surname");
            }

            if (data.givenname.value === "" || data.givenname.value === null) {
                createErrorMsg("givenname", "名前(givenname)が空欄です。入力してください。");
            } else {
                errorTrue("givenname");
            }

            if (data.date.value === "" || data.date.value === null) {
                createErrorMsg("date", "日(Date)が空欄です。入力してください。");
            } else if (data.date.value.length !== 2) {
                createErrorMsg("date", "日(date)は01~31を入力してください。");
            } else if (Number(data.date.value) < 1 || Number(data.date.value) > 31) {
                createErrorMsg("date", "日(date)は01~31を入力してください。");
            } else {
                errorTrue("date");
            }

            if (data.month.value === "" || data.month.value === null || data.month.value === 0) {
                createErrorMsg("month", "月(month)が空欄です。設定してください。");
            } else {
                errorTrue("month");
            }

            if (data.year.value === "" || data.year.value === null) {
                createErrorMsg("year", "年(year)が空欄です。入力してください。");
            } else if (Number(data.year.value) < 1900 || Number(data.year.value) > new Date().getFullYear()) {
                createErrorMsg("year", `年(year)は1900~${new Date().getFullYear()}を入力してください。`);
            } else {
                errorTrue("year");
            }

            errorMsgArr.map((value, index) => {
                if (index === 0) {
                   errorMsg = errorMsg.concat(value);
                } else {
                   errorMsg = errorMsg.concat("\n", value);
                }
                return null;
            });
             
            if (
                !errorData.nation.error &&
                !errorData.date.error &&
                !errorData.year.error &&
                !errorData.doc_type.error &&
                !errorData.passport_no.error &&
                !errorData.surname.error &&
                !errorData.givenname.error &&
                !errorData.month.error 
            ) {
                errorData.activeStep += 1;
                setData(errorData);
            } else {
                setData(errorData);
                alert(errorMsg);
                return false;
            }

        }else if(data.activeStep === 2 || data.activeStep === 3){

            const createErrorMsg = (code, errMsg) => {
                errorMsgArr.push(errMsg);
                errorData[code].error = true;
            };

            const errorTrue = (code) => {
                console.log(2);
                errorData[code].error = false;
                errorData[code].disabled = true;
            };

            if (data.status.value === "" || data.status.value === null) {
                createErrorMsg("status", "旅券等の種類が空欄です。設定してください。");
            } else {
                console.log(1);
                errorTrue("status");
            }

            if (data.land_date_d.value === "" || data.land_date_d.value === null) {
                createErrorMsg("land_date_d", "日(Date)が空欄です。入力してください。");
            } else if (data.land_date_d.value.length !== 2) {
                createErrorMsg("land_date_d", "日(date)は01~31を入力してください。");
            } else if (Number(data.land_date_d.value) < 1 || Number(data.land_date_d.value) > 31) {
                createErrorMsg("land_date_d", "日(date)は01~31を入力してください。");
            } else {
                errorTrue("land_date_d");
            }

            if (data.land_date_m.value === "" || data.land_date_m.value === null || data.land_date_m.value === 0) {
                createErrorMsg("land_date_m", "月(month)が空欄です。設定してください。");
            } else {
                errorTrue("land_date_m");
            }

            if (data.land_date_y.value === "" || data.land_date_y.value === null) {
                createErrorMsg("land_date_y", "年(year)が空欄です。入力してください。");
            } else if (Number(data.land_date_y.value) < 1900 || Number(data.land_date_y.value) > new Date().getFullYear()) {
                createErrorMsg("land_date_y", `年(year)は1900~${new Date().getFullYear()}を入力してください。`);
            } else {
                errorTrue("land_date_y");
            }
            
            errorMsgArr.map((value, index) => {
                if (index === 0) {
                    errorMsg = errorMsg.concat(value);
                } else {
                    errorMsg = errorMsg.concat("\n", value);
                }
                return null;
            });

            if (
                !errorData.status.error &&
                !errorData.land_date_d.error &&
                !errorData.land_date_m.error &&
                !errorData.land_date_y.error
            ) {
                errorData.activeStep += 1;
                setData(errorData);
            } else {
                setData(errorData);
                alert(errorMsg);
                return false;
            }

        }else if(data.activeStep === 4 || data.activeStep === 5){

            console.log(errorData);
            errorData.activeStep += 1;
            setData(errorData);
        }
    };

    const handleClickTest = () => {
        setData({
            ...data,
            surname: { ...data.surname, value: "GAIMU" },
            givenname: { ...data.givenname, value: "SAKURA" },
            nation: { ...data.nation, value: "JPN" },
            year: { ...data.year, value: 2018 },
            month: { ...data.month, value: 10 },
            date: { ...data.date, value: "28" },
            status: { ...data.status, value: 11 },
            land_date_d: { ...data.year, value: "28" },
            land_date_m: { ...data.year, value: 10 },
            land_date_y: { ...data.year, value: 2018 },
            doc_type: { ...data.year, value: 2 },
            passport_no: { ...data.year, value: "AB123456" },
            sex: { ...data.year, value: "MAN" },
        });

        setReceiptData({
            ...receiptData,
            sell_d: { ...data.year, value: "28" },
            sell_m: { ...data.year, value: 10 },
            sell_y: { ...data.year, value: 2018 },
            details: {
                0: {
                    goods_type: 1,
                    goods_name: "abc",
                    number: 10,
                    price: 10000,
                    reduced: 1,
                },
            },
        });

    }

    const handleBack = () => {
        if(data.activeStep !== 0){
            setData((prevData) => {
                return { ...data, activeStep: prevData.activeStep - 1 };
            });
        }
        window.scrollTo(0, 0);
    };

    const backButton = () => {

        const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);
        const condition = data.activeStep === 0;

        return (
            <div className={classes.buttonPosition}>
                <ConditionalWrapper
                    condition={condition}
                    wrapper={(children) => (
                        <Link to="/syori" className={classes.sidebarText}>
                            {children}
                        </Link>
                    )}
                >
                    <Button variant="contained" onClick={handleBack} className={classes.backButton}>
                        戻る
                    </Button>
                </ConditionalWrapper>
            </div>
        );
        
        /*
        if (data.activeStep === 0) {
            return (
                <Link to="/main/syori" className={classes.sidebarText}>
                    <Button
                        color="secondary"
                        onClick={() => window.scrollTo(0, 0)}
                        className={classes.button}
                        variant="contained"
                    >
                        戻る
                    </Button>
                </Link>
            );
        } else {
            return (
                <Button color="secondary" onClick={handleBack} className={classes.button} variant="contained">
                    戻る
                </Button>
            );
        }
        */
    };

    return (
        <Grid container justify="center">
            <Grid container item xs={12}>
                <Grid container item xs={12} justify="center">
                    <Grid item xs={1}>
                        {backButton()}
                    </Grid>
                    <Grid item xs={1} className={classes.buttonPosition}>
                        <Link to="/syori" disabled className={classes.sidebarText}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setData({ ...data, activeStep: data.activeStep });
                                    window.scrollTo(0, 0);
                                }}
                                className={classes.saveButton}
                            >
                                保存
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={1} className={classes.buttonPosition}>
                        <Button variant="contained" onClick={handleNext} className={classes.nextButton}>
                            {data.activeStep === 6 ? "完了" : "次へ"}
                        </Button>
                    </Grid>
                    <Grid item xs={1} className={classes.buttonPosition}>
                        <Button variant="contained" onClick={handleClickTest} className={classes.nextButton}>
                            testButton
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
