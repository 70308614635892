import React, {useContext} from 'react';
import { MyContext } from '../data/State';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment'; 
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import { LoginAPI } from "../service/LoginAPI";
import { loginEndPoint } from "../config/Config";
import {useStyles} from '../css/Login_css';

const Login = () =>{


  const classes = useStyles();

  let myContext = useContext(MyContext);

  const user = myContext.user;
  const setUser = myContext.setUser;
  
  const endpoint = loginEndPoint;
  const json = {
    "userid": user.userid,
    "passwd": user.passwd,
  }
  const config = null;

  const handleChange = (prop) => (event) => {
    setUser({...user, [prop] : event.target.value});
  };

  const handleClickShowPassword = () => {
    setUser({ ...user, showPassword: !user.showPassword });
  };

  return (
      <div>
          <form>
              <Grid className={classes.root} container direction="column" alignItems="center" spacing={1}>
                  <Grid item>
                      <FormControl className={clsx(classes.margin, classes.textField)}>
                          <TextField
                              id="user"
                              label="id"
                              value={user.username}
                              onChange={handleChange("userid")}
                          ></TextField>
                      </FormControl>
                  </Grid>
                  <Grid item>
                      <FormControl className={clsx(classes.margin, classes.textField)}>
                          <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                          <Input
                              id="standard-adornment-password"
                              type={user.showPassword ? "text" : "password"}
                              value={user.pw}
                              onChange={handleChange("passwd")}
                              endAdornment={
                                  <InputAdornment position="end">
                                      <IconButton onClick={handleClickShowPassword}>
                                          {user.showPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                  </InputAdornment>
                              }
                          />
                      </FormControl>
                  </Grid>
                  <Grid item>
                      <Button
                          className={classes.button}
                          type="submit"
                          onClick={(event) => LoginAPI(endpoint, json, config, event)}
                          variant="contained"
                          color="primary"
                      >
                          submit
                      </Button>
                  </Grid>
              </Grid>
          </form>
      </div>
  );
}

export default Login;