export const loginEndPoint = "http://52.193.32.224:8080/userLogin";
export const kokuzeiEndPoint = "http://ec2-52-193-32-224.ap-northeast-1.compute.amazonaws.com:8080/tax/registration";
export const kokuzeiConfig = {
    headers: {
        "content_type": "application/json"
    }
}

export const reqRequestEndPoint = "http://52.193.32.224:8080/reqRequest";
export const headersConfig = (config) => {

    const result = {
            headers: config
    };
    
    return result;
}

export const getImageFileEndPoint = "http://52.193.32.224:8080/rsrc/";

export const countStatusEndPoint = "http://52.193.32.224:8080/countStatus";
export const getListEndPoint = "http://52.193.32.224:8080/listStatus";