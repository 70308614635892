import React, { useContext, useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import { MyContext } from "../../data/State";
import image from "../../source/stump.png";
import TextField from "@material-ui/core/TextField";
import HelpIcon from "@material-ui/icons/Help";
import IconButton from "@material-ui/core/IconButton";
import HelpDialog from "../../components/HelpDialog";
import { MonthCode, MonthCodeTitle } from "../../components/text/MonthCode";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import { ordinaryPostAPI } from "../../service/ordinaryAPI";
import { reqRequestEndPoint, getImageFileEndPoint } from "../../config/Config";
import Main from "../../screen/Main";

const useStyles = makeStyles((theme) => ({
    title: {
        margin: "0px 0px 50px 0px",
    },
    image: {
        width: "640px",
        height: "440px",
    },
    textFieldStyle_S: {
        width: 140,
    },
    monthSelectStyle: {
        width: 140,
        margin: "-10px 0px 0px 0px",
    },
    selectPosition: {
        margin: "-20px 0px 0px 0px",
    },
    textLabel: {
        margin: "10px 0px 10px 0px",
        fontSize: 17,
        fontWeight: "bold",
    },
    selectLabel: {
        fontWeight: "bold",
    },
    clearIcon: {
        left: 10,
    },
    secondItem: {
        position: "relative",
        bottom: 150,
    },
    monthClearIcon: {
        left: 10,
        top: 5,
    },
}));

function Step_5() {
    const classes = useStyles();

    const initLocalData = {};

    const initLocalDataForm = (id, name, helpText, errorText, placeholder, error) => {
        initLocalData[name] = {};
        initLocalData[name].id = id;
        initLocalData[name].name = name;
        initLocalData[name].helpText = helpText;
        initLocalData[name].errorText = errorText;
        initLocalData[name].placeholder = placeholder;
        initLocalData[name].error = error;
    };

    const initLocalDataFunc = () => {
        initLocalDataForm(9, "status", "在留資格", "", "", false);
        initLocalDataForm(10, "land_date_d", "日(Date)", "", "01", false);
        initLocalDataForm(11, "land_date_m", "月(Month)", "", "04", false);
        initLocalDataForm(12, "land_date_y", "年(Year)", "", "2016", false);

        return initLocalData;
    };

    //입력폼 데이터
    const [localData, setLocalData] = useState(initLocalDataFunc);
    const [open_m, setOpen_m] = useState(false);

     const [pic_1_name, setPic_1_name] = useState();

     useEffect(() => {
         const jKey = sessionStorage.getItem("jKey");

         async function getImageName() {
             const temp = await ordinaryPostAPI(reqRequestEndPoint, { rid: 2 }, jKey).then((result) => {
                 console.log(result);
                 return result;
             });

             setPic_1_name(temp.data.data[0].pic2);
         }

         getImageName();
     }, []);

     let imageUrl;

     if (pic_1_name) {
         imageUrl = getImageFileEndPoint + pic_1_name;
     }

     console.log(imageUrl);

    //전역 변수 불러오기
    let myContext = useContext(MyContext);
    const data = myContext.data;
    const setData = myContext.setData;

    const handleOnChange = (code) => (e) => {
        const value = e.target.value;
        const localError = checkValidation(code, value);

        const tempObj = JSON.parse(JSON.stringify(data));

        //tempObj[code].error=false;

        switch (code) {
            case "status":
                if (value) {
                    tempObj[code].value = value;
                    tempObj[code].error = false;
                } else {
                    tempObj[code].value = value;
                    tempObj[code].error = true;
                }
                break;
            case "land_date_d":
                if (!localError) {
                    tempObj[code].value = value;
                    tempObj[code].error = false;
                }
                break;
            case "land_date_m":
                if (value) {
                    tempObj[code].value = value;
                    tempObj[code].error = false;
                } else {
                    tempObj[code].value = value;
                    tempObj[code].error = true;
                }
                break;
            case "land_date_y":
                if (!localError) {
                    tempObj[code].value = value;
                    tempObj[code].error = false;
                }
                break;
            default:
                break;
        }

        console.log(tempObj);

        setData(tempObj);
    };

    const checkValidation = (code, value) => {
        let errorText = "";
        let error = false;

        const regExp_space = /\s/g;
        const regExp_onlyNumber = /^[0-9]*$/g;
        const regExp_length_4 = /^.{0,4}$/g;
        const regExp_length_2 = /^.{0,2}$/g;

        switch (code) {
            case "status":
                if (value === "") {
                    errorText = "無し不要";
                    error = true;
                }
                break;
            case "land_date_d":
                if (regExp_space.test(value)) {
                    errorText = "スペース不要";
                    error = true;
                    break;
                }

                if (!regExp_onlyNumber.test(value)) {
                    errorText = "数字以外不要";
                    error = true;
                    break;
                }

                if (!regExp_length_2.test(value)) {
                    errorText = "2文字まで";
                    error = true;
                    break;
                }

                break;
            case "land_date_m":
                if (value === "") {
                    errorText = "無し不要";
                    error = true;
                }
                break;

            case "land_date_y":
                if (regExp_space.test(value)) {
                    errorText = "スペース不要";
                    error = true;
                    break;
                }

                if (!regExp_onlyNumber.test(value)) {
                    errorText = "数字以外不要";
                    error = true;
                    break;
                }

                if (!regExp_length_4.test(value)) {
                    errorText = "4文字まで";
                    error = true;
                    break;
                }

                break;

            default:
                errorText = "prop's value error";
                error = true;
                break;
        }

        setLocalData({ ...localData, [code]: { ...localData[code], errorText: errorText, error: error } });
        return error;
    };

    const textField = (code, style) => {
        let labelMsg = "";

        if (localData[code].error) {
            labelMsg = localData[code].errorText;
        } else if (data[code].error) {
            labelMsg = localData[code].helpText;
        } else {
            labelMsg = localData[code].helpText;
        }

        return (
            <TextField
                disabled={data[code].disabled}
                required={data[code].required}
                label={labelMsg}
                error={data[code].error || localData[code].error}
                className={classes[style]}
                variant="outlined"
                size="small"
                value={data[code].value !== "" ? data[code].value : ""}
                onChange={handleOnChange(code)}
                placeholder={localData[code].placeholder}
                InputProps={{
                    endAdornment: (
                        <>
                            {!data[code].disabled ? (
                                <>
                                    <IconButton
                                        className={classes.clearIcon}
                                        size="small"
                                        onClick={() => {
                                            setData({ ...data, [code]: { ...data[code], value: "" } });
                                            setLocalData({
                                                ...localData,
                                                [code]: {
                                                    ...localData[code],
                                                    errorText: localData[code].helpText,
                                                    error: false,
                                                },
                                            });
                                        }}
                                    >
                                        {}
                                        <ClearIcon />
                                    </IconButton>
                                    <IconButton
                                        className={classes.clearIcon}
                                        size="small"
                                        onClick={() => {
                                            setData({ ...data, [code]: { ...data[code], disabled: true } });
                                        }}
                                    >
                                        {}
                                        <SaveIcon />
                                    </IconButton>
                                </>
                            ) : (
                                <IconButton
                                    className={classes.clearIcon}
                                    size="small"
                                    onClick={() => {
                                        setData({ ...data, [code]: { ...data[code], disabled: false } });
                                    }}
                                >
                                    {}
                                    <EditIcon />
                                </IconButton>
                            )}
                        </>
                    ),
                }}
            />
        );
    };

    //JSX
    return (
        <>
        <Main></Main>
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h4" className={classes.title}>
                    ・Step 4 入国スタンプ情報確認
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <img src={imageUrl} className={classes.image} alt="passport_image"></img>
            </Grid>
            <Grid container item xs={6} className={classes.inputForm}>
                <Grid item xs={3}>
                    <Typography className={classes.selectLabel}>①　在留資格</Typography>
                </Grid>
                <Grid container item xs={9}>
                    <Grid item xs={6}>
                        <FormControl className={classes.selectPosition} error={data.status.error}>
                            <InputLabel required={data.status.required}>旅券等の種類</InputLabel>
                            <Select
                                disabled={data.status.disabled}
                                onChange={handleOnChange("status")}
                                value={data.status.value !== "" ? data.status.value : ""}
                                native
                            >
                                <option value={""}></option>
                                <option value={11}>短期滞在</option>
                                <option value={14}>外交</option>
                                <option value={17}>公用</option>
                                <option value={21}>芸術</option>
                                <option value={24}>宗教</option>
                                <option value={27}>報道</option>
                                <option value={31}>法律・会計業務</option>
                                <option value={34}>医療</option>
                                <option value={37}>興行</option>
                                <option value={41}>文化活動</option>
                                <option value={44}>留学</option>
                                <option value={47}>研修</option>
                                <option value={51}>家族滞在</option>
                                <option value={54}>特定活動</option>
                                <option value={81}>日本人の配偶者等</option>
                                <option value={85}>永住者の配偶者等</option>
                                <option value={91}>上陸許可書による入国</option>
                                <option value={95}>非居住者に該当する日本国籍の者</option>
                                <option value={99}>その他</option>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        {data.status.disabled ? (
                            <IconButton
                                className={classes.clearIcon}
                                size="small"
                                onClick={() => {
                                    setData({ ...data, status: { ...data["status"], disabled: false } });
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        ) : (
                            <IconButton
                                className={classes.clearIcon}
                                size="small"
                                onClick={() => {
                                    setData({ ...data, status: { ...data["status"], disabled: true } });
                                }}
                            >
                                <SaveIcon />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
                <Grid container item xs={3} className={classes.secondItem}>
                    <Grid item xs={8}>
                        <Typography className={classes.textLabel}>②　入国日</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <IconButton
                            onClick={() => {
                                setOpen_m(true);
                            }}
                            className={classes.helpIcon}
                        >
                            <HelpIcon></HelpIcon>
                        </IconButton>
                        {open_m ? (
                            <HelpDialog
                                onClose={() => {
                                    setOpen_m(false);
                                }}
                                open={open_m}
                                text={MonthCode}
                                title={MonthCodeTitle}
                            />
                        ) : null}
                    </Grid>
                </Grid>
                <Grid container item xs={9} className={classes.secondItem}>
                    <Grid item xs={4}>
                        {textField("land_date_d", "textFieldStyle_S")}
                    </Grid>
                    <Grid container item xs={4}>
                        <Grid item xs={9}>
                            <FormControl className={classes.monthSelectStyle} error={data.land_date_m.error}>
                                <InputLabel required={data.land_date_m.required}>月(Month)</InputLabel>
                                <Select
                                    disabled={data.land_date_m.disabled}
                                    onChange={handleOnChange("land_date_m")}
                                    value={data.land_date_m.value !== "" ? data.land_date_m.value : ""}
                                    native
                                >
                                    <option value={""}></option>
                                    <option value={"01"}>JAN/01</option>
                                    <option value={"02"}>FEB/02</option>
                                    <option value={"03"}>MAR/03</option>
                                    <option value={"04"}>APR/04</option>
                                    <option value={"05"}>MAY/05</option>
                                    <option value={"06"}>JUN/06</option>
                                    <option value={"07"}>JUL/07</option>
                                    <option value={"08"}>AUG/08</option>
                                    <option value={"09"}>SEP/09</option>
                                    <option value={"10"}>OCT/10</option>
                                    <option value={"11"}>NOV/11</option>
                                    <option value={"12"}>DEC/12</option>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            {data.land_date_m.disabled ? (
                                <IconButton
                                    className={classes.monthClearIcon}
                                    size="small"
                                    onClick={() => {
                                        setData({ ...data, land_date_m: { ...data["land_date_m"], disabled: false } });
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            ) : (
                                <IconButton
                                    className={classes.monthClearIcon}
                                    size="small"
                                    onClick={() => {
                                        setData({ ...data, land_date_m: { ...data["land_date_m"], disabled: true } });
                                    }}
                                >
                                    <SaveIcon />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        {textField("land_date_y", "textFieldStyle_S")}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </>
    );
}

export default Step_5;
