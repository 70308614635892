import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import Exercise from './exercise/Exercise';


ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);


/*
ReactDOM.render(
    <BrowserRouter>
        <Exercise />
    </BrowserRouter>,
    document.getElementById("root")
);


*/