import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { MyContext } from "../../data/State";
import image from "../../source/receipt.jpg";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import { ordinaryPostAPI } from "../../service/ordinaryAPI";
import { reqRequestEndPoint, getImageFileEndPoint } from "../../config/Config";
import Main from "../../screen/Main";


const useStyles = makeStyles((theme) => ({
    title: {
        margin: "0px 0px 50px 0px",
    },
    imageContainer: {
        width: "500px",
        height: "400px",
        overflow: "scroll",
    },
    tablePosition: {
        margin: "50px 0px 0px 0px",
        border: "solid 4px rgb(244,245,245)",
    },
    TableHeadCell: {
        backgroundColor: "rgb(244,245,245)",
        color: theme.palette.common.black,
        fontSize: 20,
        fontWeight: "bold",
        border: "solid 1px rgb(193,199,208,1)",
    },
    sellDate: {
        margin: "30px 0px 0px 0px",
    },
    label: {
        position: "relative",
        top: 10,
        fontWeight: "bold",
    },
    selectPosition: {
        position: "relative",
        bottom: 10,
    },
    clearIcon: {
        left: 10,
    },
    tableContainer: {
        Height: "400px",
    },
    buttonPosition: {
        margin: "50px 0px 0px 0px",
    },
    seisanButton: {
        backgroundColor: "rgb(156,39,176)",
        color: "white",
    },
}));

const SellingDateText = ({ code, setReceiptData, receiptData }) => {
    const classes = useStyles();

    let labelMsg = '';
    let placeHolder = '';

    switch (code) {
        case "sell_d":
            labelMsg = "日(Date)";
            placeHolder = '28';
            break;
        case "sell_m":
            labelMsg = "月(Month)";
            placeHolder = "02";
            break;
        case "sell_y":
            labelMsg = "年(Year)";
            placeHolder = "2018";
            break;
        default:
            break;
    }

    const handleChangeSellingDate = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        setReceiptData({ ...receiptData, [name]: {...receiptData[name], value: value} });
    };

    return (
        <TextField
            name={code}
            placeholder={placeHolder}
            label={labelMsg}
            variant="outlined"
            size="small"
            value={receiptData[code].value || ''}
            onChange={handleChangeSellingDate}
            InputProps={{
                endAdornment: (
                    <IconButton
                        className={classes.clearIcon}
                        size="small"
                        onClick={() => {
                            setReceiptData({ ...receiptData, [code]: { ...receiptData[code], value: "" } });
                        }}
                    >
                        <ClearIcon />
                    </IconButton>
                ),
            }}
        />
    );
}



const ReceiptText = ({ code, setReceiptData, receiptData }) => {
    const classes = useStyles();

    let labelMsg = "";
    let placeHolder = "";

    switch (code) {
        case "goods_name":
            labelMsg = "品名を入力してください。";
            placeHolder = "ヨコオワタル";
            break;
        case "number":
            labelMsg = "数量";
            placeHolder = "2";  
            break;
        case "price":
            labelMsg = "合計価格";
            placeHolder = "50000";
            break;
        default:
            break;
    }

    const handleChangeSellingDate = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        setReceiptData({ ...receiptData, [name]: { ...receiptData[name], value: value } });
    };

    return (
        <TextField
            name={code}
            placeholder={placeHolder}
            label={labelMsg}
            variant="outlined"
            size="small"
            value={receiptData[code].value !== "" ? receiptData[code].value : ""}
            onChange={handleChangeSellingDate}
            InputProps={{
                endAdornment: (
                    <IconButton
                        className={classes.clearIcon}
                        size="small"
                        onClick={() => {
                            setReceiptData({ ...receiptData, [code]: { ...receiptData[code], value: "" } });
                        }}
                    >
                        <ClearIcon />
                    </IconButton>
                ),
            }}
        />
    );
}

const EditReceiptText = ({ name, value, index, setReceiptData, receiptData }) => {

    const handleChangeText = (e, index) => {
        const value = e.target.value;
        const name = e.target.name;

        setReceiptData({
            ...receiptData,
            details: {
                ...receiptData.details,
                [index]: { ...receiptData.details[index], [name]: value },
            },
        });
    };

    return (
        <TextField
            name={name}
            variant="outlined"
            size="small"
            value={value[1][name] !== "" ? value[1][name] : ""}
            onChange={(e) => {
                handleChangeText(e, index);
            }}
        />
    );
};

const ResultTableRow = ({ receiptData ,setReceiptData,}) => {
    const classes = useStyles();

    const handleClickEditIcon = (index) => {
        setReceiptData({
            ...receiptData,
            details: { 
                ...receiptData.details, 
                [index]: { ...receiptData.details[index], canChange: true } 
            },
        });
    };

    const handleChangeGoodsType = (e, index) => {
        const value = Number(e.target.value);
        const name = e.target.name;

        setReceiptData({
            ...receiptData,
            details: {
                ...receiptData.details,
                [index]: { ...receiptData.details[index], [name]: value },
            },
        });
    };

    const handleClickSaveIcon = (index) => {
        setReceiptData({
            ...receiptData,
            details: {
                ...receiptData.details,
                [index]: { ...receiptData.details[index], canChange: false },
            },
        });
    }

    const handleClickDeleteIcon = (index) => {
        
        const result = Object.keys(receiptData.details).filter(value=>Number(value) !== index).map((value)=>{
            return receiptData.details[value];
        })

        setReceiptData({...receiptData,
            details:result});
    }


    const result = Object.entries(receiptData.details).map((value, index) => {
        if (!value[1].canChange) {
            return (
                <TableRow key={index}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{value[1].goods_type === 1 ? "一般物品" : "消耗品"}</TableCell>
                    <TableCell align="center">{value[1].goods_name}</TableCell>
                    <TableCell align="center">{value[1].number}</TableCell>
                    <TableCell align="center">{value[1].price}</TableCell>
                    <TableCell align="center">{value[1].reduced === 0 ? "10%" : "8%"}</TableCell>
                    <TableCell>
                        <IconButton
                            size="small"
                            onClick={() => {
                                handleClickEditIcon(index);
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={() => {
                                handleClickDeleteIcon(index);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
            );
        } else {
            return (
                <TableRow key={index}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                        <FormControl className={classes.selectPosition}>
                            <InputLabel>物品区分</InputLabel>
                            <Select
                                value={value[1].goods_type !== "" ? value[1].goods_type : ""}
                                onChange={(e) => {
                                    handleChangeGoodsType(e, index);
                                }}
                                name="goods_type"
                                native
                            >
                                <option value={""}></option>
                                <option value={1}>一般物品</option>
                                <option value={2}>消耗品</option>
                            </Select>
                        </FormControl>
                    </TableCell>
                    <TableCell align="center">
                        <EditReceiptText
                            name="goods_name"
                            value={value}
                            index={index}
                            receiptData={receiptData}
                            setReceiptData={setReceiptData}
                        />
                    </TableCell>
                    <TableCell align="center">
                        <EditReceiptText
                            name="number"
                            value={value}
                            index={index}
                            receiptData={receiptData}
                            setReceiptData={setReceiptData}
                        />
                    </TableCell>
                    <TableCell align="center">
                        <EditReceiptText
                            name="price"
                            value={value}
                            index={index}
                            receiptData={receiptData}
                            setReceiptData={setReceiptData}
                        />
                    </TableCell>
                    <TableCell align="center">
                        <FormControl className={classes.selectPosition}>
                            <InputLabel>税率</InputLabel>
                            <Select
                                value={value[1].reduced !== "" ? value[1].reduced : ""}
                                onChange={(e) => {
                                    handleChangeGoodsType(e, index);
                                }}
                                name="reduced"
                                native
                            >
                                <option value={""}></option>
                                <option value={0}>10%</option>
                                <option value={1}>8%</option>
                            </Select>
                        </FormControl>
                    </TableCell>
                    <TableCell>
                        <IconButton
                            size="small"
                            onClick={() => {
                                handleClickSaveIcon(index);
                            }}
                        >
                            <SaveIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
            );
        }
    });

    return result;
};


function Step_6() {
    const classes = useStyles();

    //전역 변수 불러오기
    const myContext = useContext(MyContext);
    const receiptData = myContext.receiptData;
    const setReceiptData = myContext.setReceiptData;

    const [count,setCount] = useState(0);

    console.log(receiptData);

    const [pic_1_name, setPic_1_name] = useState();

    useEffect(() => {
        const jKey = sessionStorage.getItem("jKey");

        async function getImageName() {
            const temp = await ordinaryPostAPI(reqRequestEndPoint, { rid: 2 }, jKey).then((result) => {
                console.log(result);
                return result;
            });

            setPic_1_name(temp.data.data[0].pic3);
        }

        getImageName();
    }, []);

    let imageUrl;


    if (pic_1_name) {

            
        console.log(typeof pic_1_name);

        imageUrl = pic_1_name.split(",");

        imageUrl = imageUrl.map((value)=>{
            return getImageFileEndPoint + value;
        })

    }

    console.log(imageUrl);

    useEffect(()=>{
        let generalTotalCost = 0;
        let consumTotalCost = 0;

        Object.entries(receiptData.details).map((value) => {
            if (value[1].goods_type === 1) {
                generalTotalCost = generalTotalCost + Number(value[1].price);
            } else {
                consumTotalCost = consumTotalCost + Number(value[1].price);
            }

            return null;
        });

        setReceiptData({
            ...receiptData,
            generalTotal: { ...receiptData.generalTotal, value: generalTotalCost },
            consumTotal: { ...receiptData.consumTotal, value: consumTotalCost },
        });
        
    },[receiptData.details])

    const handleClickResist = () => {
        const pushArr = {
            goods_type: receiptData.goods_type.value,
            goods_name: receiptData.goods_name.value,
            number: receiptData.number.value,
            price: receiptData.price.value,
            reduced: receiptData.reduced.value,
            canChange: false,
        };

        setReceiptData({
            ...receiptData,
            details: { ...receiptData.details, [count]: pushArr },
            goods_type: { ...receiptData.goods_type, value: "" },
            goods_name: { ...receiptData.goods_name, value: "" },
            number: { ...receiptData.number, value: "" },
            price: { ...receiptData.price, value: "" },
            reduced: { ...receiptData.reduced, value: "" },
        });

        setCount(prev=>prev + 1);
    };


    const handleChangeGoodsType = (e) => {
        const value = Number(e.target.value);
        const name = e.target.name;

        setReceiptData({...receiptData,[name]:{...receiptData[name],value:value}});
    }
    

    //JSX
    return (
        <>
        <Main/>
        <Grid container justify="center">
            <Grid item xs={12}>
                <Typography variant="h4" className={classes.title}>
                    ・Step 5 商品情報登録
                </Typography>
            </Grid>
            <Grid container item xs={6} className={classes.imageContainer} justify="center" alignItems="center">
                {imageUrl ? imageUrl.map((value,index)=>{
                    return (
                        <img src={value} key={index} alt="receipt_image"/>
                    )
                }) : null}
            </Grid>
            <Grid container item xs={12} className={classes.sellDate}>
                <Grid item xs={1} className={classes.label}>
                    領収証年月日
                </Grid>
                <Grid container item xs={11}>
                    <Grid item xs={3}>
                        <SellingDateText code="sell_y" receiptData={receiptData} setReceiptData={setReceiptData} />
                    </Grid>
                    <Grid item xs={3}>
                        <SellingDateText code="sell_m" receiptData={receiptData} setReceiptData={setReceiptData} />
                    </Grid>
                    <Grid item xs={3}>
                        <SellingDateText code="sell_d" receiptData={receiptData} setReceiptData={setReceiptData} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.tablePosition}>
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.TableHeadCell} align="center">
                                    No.
                                </TableCell>
                                <TableCell className={classes.TableHeadCell} align="center">
                                    物品区分
                                </TableCell>
                                <TableCell className={classes.TableHeadCell} align="center">
                                    品名
                                </TableCell>
                                <TableCell className={classes.TableHeadCell} align="center">
                                    数量
                                </TableCell>
                                <TableCell className={classes.TableHeadCell} align="center">
                                    販売価格
                                </TableCell>
                                <TableCell className={classes.TableHeadCell} align="center">
                                    税率
                                </TableCell>
                                <TableCell className={classes.TableHeadCell} align="center">
                                    Icon
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center">
                                    <FormControl className={classes.selectPosition}>
                                        <InputLabel>物品区分</InputLabel>
                                        <Select
                                            value={
                                                receiptData.goods_type.value !== "" ? receiptData.goods_type.value : ""
                                            }
                                            onChange={handleChangeGoodsType}
                                            name="goods_type"
                                            native
                                        >
                                            <option value={""}></option>
                                            <option value={1}>一般物品</option>
                                            <option value={2}>消耗品</option>
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell align="center">
                                    <ReceiptText
                                        code="goods_name"
                                        receiptData={receiptData}
                                        setReceiptData={setReceiptData}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <ReceiptText
                                        code="number"
                                        receiptData={receiptData}
                                        setReceiptData={setReceiptData}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <ReceiptText
                                        code="price"
                                        receiptData={receiptData}
                                        setReceiptData={setReceiptData}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <FormControl className={classes.selectPosition}>
                                        <InputLabel>税率</InputLabel>
                                        <Select
                                            value={receiptData.reduced.value !== "" ? receiptData.reduced.value : ""}
                                            onChange={handleChangeGoodsType}
                                            name="reduced"
                                            native
                                        >
                                            <option value={""}></option>
                                            <option value={0}>10%</option>
                                            <option value={1}>8%</option>
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell align="center">
                                    <Button variant="contained" onClick={handleClickResist}>
                                        登録
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <ResultTableRow receiptData={receiptData} setReceiptData={setReceiptData} />
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid container item xs={12} className={classes.tablePosition}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.TableHeadCell} align="center">
                                    一般物品合計額
                                </TableCell>
                                <TableCell className={classes.TableHeadCell} align="center">
                                    消耗品合計額
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="center">{receiptData.generalTotal.value}</TableCell>
                                <TableCell align="center">{receiptData.consumTotal.value}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
        </>
    );
}

export default Step_6;
